import { getMessages, GetMessages } from "../services/core-api-adapter";
import { useQuery } from "@tanstack/react-query";

export default function useGetMessages(
  skipCount?: number,
  listLength?: number
) {
  const controller = new AbortController();

  const { data, isPending, isLoading, isError, error, refetch } = useQuery({
    queryKey: ["getMessagesData", skipCount, listLength],
    queryFn: async () => {
      const response = await getMessages(skipCount, listLength, {
        signal: controller.signal,
      });
      return response as GetMessages;
    },
  });

  return {
    messages: isLoading ? [] : data?.appNotifications,
    messagesCount: data?.total,
    unreadMessagesCount: data?.unreadTotal,
    skipCount: data?.skipCount,
    listLength: data?.listLength,
    messagesFetchError: isError || error,
    messagesNotFoundError: isError && (error as any)?.status === 404,
    isMessagesLoading: isLoading || isPending,
    refetchMessages: refetch,
  };
}
